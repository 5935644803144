import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './privatePolicy.module.scss';


const PrivatePolicy = () => {
  return (
    <main className={styles.container_policy}>
      <title>Privacidad y Politicas</title>
      <article className={styles.container_policy__content}>
        <div className={styles.container_policy__content_logo}>
          <StaticImage src="../../images/icon.webp" alt="logo" loading="lazy"
            layout="constrained"
            formats={["auto", "webp", "avif"]} />
        </div>
        <hgroup>
          <h1 className={styles.container_policy__title}>
            Política de privacidad
          </h1>
          <h3 className={styles.container_policy__subtitle}>
            Responsable del Tratamiento{' '}
            <span className={styles.container_policy__name}>
              Connecting Tech People
            </span>
          </h3>
          <h4 className={styles.container_policy__subtitle}>CIF B01638196</h4>
          <h5 className={styles.container_policy__subtitle}>
            Pase de la Castellana, 91. 4to. Madrid, España
          </h5>
          <h5 className={styles.container_policy__subtitle}>
            <a href="www.connectingtechpeople.com" target="_blank">
              www.connectingtechpeople.com
            </a>
          </h5>
        </hgroup>
        <p className={styles.container_policy__text}>
          Se compromete en virtud del Reglamento UE 2016/679 a proteger su
          privacidad e información personal, proporcionando una conexión segura
          al usuario para evitar alteraciones así como accesos no autorizados a
          su información personal.
        </p>
        <p className={styles.container_policy__text}>
          Tipo de información que recopilamos: Datos identificativos Datos
          académicos y profesionales Datos de detalle de empleo Datos de
          circunstancias personales Datos económicos y financieros Dicha
          información es necesaria para realizar las finalidades que se
          describen en el siguiente apartado, de lo contrario no podremos
          desarrollar la actividad de búsqueda de candidatos y selección de
          personal.
        </p>
        <p className={styles.container_policy__text}>
          Plazo de conservación de la información personal Le informamos también
          que nuestra entidad conservará su información personal durante un
          plazo de que dure el proceso de selección, pudiendo ejercer su derecho
          de acceso, rectificación, cancelación y limitación de su tratamiento,
          así como oponerse al mismo enviando un solicitud por escrito a Pase de
          la Castellana, 91. 4to. Madrid, España o a
          protecciondatos@connectingtechpeople.com junto con una copia de su
          DNI.
        </p>
        <p className={styles.container_policy__text}>
          Cómo recopilamos la información:{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          obtiene su información de varias maneras, todas ellas facilitadas por
          usted: A través de este sitio web A través de otros sitios web
          colaboradores donde usted se ha inscrito en una oferta de trabajo.
        </p>
        <p className={styles.container_policy__text}>
          Finalidad y usos La información recopilada en el sitio web únicamente
          será utilizada para las siguientes finalidades: Gestionar su solicitud
          de empleo Gestionar su CV Informarle de las ofertas laborales
          existentes. Transferencias Internacionales{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no transferirá su información personal a terceros países sin su previo
          consentimiento expreso.
        </p>
        <p className={styles.container_policy__text}>
          Portabilidad de los datos Le informamos que el único servicio de cloud
          computing que utilizamos es google drive, pudiendo consultar sus
          condiciones de privacidad en el siguiente enlace:{' '}
          <a href="https://www.google.es/intl/es/policies/privacy/">
            https://www.google.es/intl/es/policies/privacy/
          </a>
        </p>
        <p className={styles.container_policy__text}>
          Reclamación ante la autoridad de control Le informamos que tiene
          derecho a presentar una denuncia o reclamación si considera que sus
          datos se están utilizando de manera incorrecta ante la Autoridad de
          Control que es la Agencia Española de Protección de Datos cuyo
          domicilio se encuentra en la C/ Jorge Juan nº 6, 28001 de Madrid.
        </p>
        <p className={styles.container_policy__text}>
          Seguimiento de comportamiento y señales de no rastreo Connecting Tech
          Peoplepuede utilizar tecnologías de recopilación de datos para conocer
          información sobre sus actividades en línea y en sitios web de terceros
          u otros servicios de seguimiento de comportamientos. Para ello
          consulte nuestra Política de cookies.
        </p>
        <p className={styles.container_policy__text}>
          Privacidad de los menores{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          tiene especial cuidado en proteger la privacidad de los menores.
          Nuestros sitios web son sitios para el público en general. No
          permitimos que menores de 16 años se registren, ni almacenamos
          información personal de aquellos usuarios que sean menores de 16 años.
        </p>
        <p className={styles.container_policy__text}>
          Enlaces A otras páginas web{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no controla, las políticas de privacidad o las prácticas de los sitios
          web de terceros ni asume responsabilidad alguna por éstos. El usuario
          reconoce y acepta que{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no será responsable de los sitios web o recursos externos, y no
          suscribe ningún tipo de publicidad, productos u otros materiales
          ofrecidos a través de dichos sitios o recursos.
        </p>
        <p className={styles.container_policy__text}>
          El usuario reconoce y acepta que{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no será responsable de las pérdidas o daños en que el usuario pueda
          incurrir a consecuencia de la disponibilidad de los mencionados sitios
          web o recursos externos, o a consecuencia de la credibilidad que
          otorgue a la exhaustividad, precisión o existencia de cualquier tipo
          de publicidad, productos u otros materiales ofrecidos a través de
          dichos sitios o recursos.
        </p>
        <p className={styles.container_policy__text}>
          De otro lado, el usuario que quiera introducir enlaces al sitio web
          desde sus propios sitios web estará obligado a cumplir las condiciones
          siguientes: El enlace únicamente vinculará con la página, no pudiendo
          reproducirla de ninguna forma De acuerdo con la normativa de
          aplicación, no podrán establecerse frames o marcos de cualquier tipo
          que rodeen el portal o permitan visualizarlo a través de direcciones
          de Internet distintas o conjuntamente con contenidos ajenos al mismo,
          de forma que produzca, o pueda producir, error o confusión en los
          usuarios sobre la procedencia del servicio o sus contenidos, implique
          un acto de comparación o imitación desleal, sirva para aprovechar la
          reputación, marca y prestigio de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          o se haga de cualquier otro modo que esté prohibido por la Ley.
        </p>
        <p className={styles.container_policy__text}>
          Desde el sitio web que realice el enlace no podrá efectuarse ningún
          tipo de manifestación falsa o inexacta sobre{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>
          , sobre la calidad de sus servicios o sobre sus empleados o
          profesionales.
        </p>
        <p className={styles.container_policy__text}>
          El remitente no podrá utilizar la marca o cualquier otro signo
          distintivo de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          dentro de su sitio web, salvo en los casos autorizados por la ley o
          permitidos expresamente por{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          El sitio web que establezca el enlace deberá observar la legalidad
          vigente y no podrá disponer de o enlazar con contenidos ilícitos,
          nocivos, contrarios a la moral y a las buenas costumbres, que
          produzcan o puedan producir la falsa idea de que{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          respalda o apoya, las ideas, manifestaciones o actuaciones del
          remitente o que resulten inadecuados en relación con la actividad
          desarrollada por{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>
          , teniendo en cuenta los contenidos y la temática general del sitio
          web donde se establezca el enlace. Finalmente, los usuarios que
          inserten contenidos de otros usuarios en sus propias páginas web no
          estarán sujetos a lo dispuesto en el presente punto pues, en sentido
          estricto, las llamadas que se producen desde sus propias páginas web a
          dichos contenidos no son enlaces a los mismos, ni al portal de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          Sus propias web “llaman” al código de los contenidos publicados
          Connecting Tech People y dichos contenidos se reproducen en sus
          propias páginas web.
        </p>
        <p className={styles.container_policy__text}>
          Enlaces al canal del prestador en otras plataformas y redes sociales{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          pone a disposición de los usuarios, a través de diferentes
          herramientas y aplicaciones, medios de enlace que permiten a los
          usuarios acceder a los canales y páginas del sitio web que{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          mantiene en diferentes plataformas y redes sociales pertenecientes y/o
          gestionadas por terceros. La inclusión de estos enlaces en el sitio
          web tiene por único objeto facilitar a los usuarios el acceso a dichos
          canales en las diferentes plataformas y redes sociales.
        </p>
        <p className={styles.container_policy__text}>
          El establecimiento de estas aplicaciones no implica la existencia de
          relación alguna entre{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          y el titular, fabricante o distribuidor de la plataforma enlazada,
          como tampoco la aceptación y aprobación por parte de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          de sus contenidos y/o servicios, siendo su titular, fabricante o
          distribuidor el único responsable de los mismos.
        </p>
        <p className={styles.container_policy__text}>
          En ningún caso{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          comparte cualquier red social que se incorpore en el futuro ningún
          tipo de información privada sobre sus usuarios, siendo su única
          finalidad la establecida en las presentes condiciones, así como en la
          política de privacidad del sitio web. En este sentido, toda la
          información que el propio usuario desee proporcionar a estas
          plataformas será bajo su propia responsabilidad, no interviniendo{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          en dicho proceso.
        </p>
        <p className={styles.container_policy__text}>
          La activación y uso de estas aplicaciones puede conllevar la
          identificación y autenticación del usuario (login/contraseña) en las
          plataformas correspondientes, completamente externas al sitio web y
          fuera del control de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>
          . Al acceder a dichas redes externas, el usuario ingresa en un entorno
          no controlado por{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no asumirá ninguna responsabilidad sobre la configuración de seguridad
          de dichos entornos.
        </p>
        <p className={styles.container_policy__text}>
          Dado que{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no tiene control alguno sobre el contenido alojado en dichos canales,
          el usuario reconoce y acepta que{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no asume responsabilidad alguna por el contenido ni por los servicios
          a los que el usuario pueda acceder en dichas páginas, ni por cualquier
          contenido, productos, servicios, publicidad, ni cualquier otro
          material disponible en los mismos.
        </p>
        <p className={styles.container_policy__text}>
          Por tal motivo, el usuario debe extremar la prudencia en la valoración
          y utilización de la información, contenidos y servicios existentes en
          los canales enlazados, y sobre la información propia o de terceros que
          quiera compartir en dichos canales.
        </p>
        <p className={styles.container_policy__text}>
          Enlaces en otras páginas web con destino a{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>
          desde aquellas páginas que contengan materiales, información o
          contenidos ilícitos, ilegales, degradantes, obscenos y, en general,
          que contravengan las leyes, la moral o el orden público, o las normas
          sociales generalmente aceptadas.
        </p>
        <p className={styles.container_policy__text}>
          En todo caso, los usuarios podrán establecer enlaces en sus
          respectivas páginas web que dirijan al sitio web, siempre y cuando
          cumplan con las siguientes condiciones:
        </p>

        <p className={styles.container_policy__text}>
          El enlace no podrá reproducir el contenido del sitio web o partes del
          mismo de ninguna forma.
        </p>
        <p className={styles.container_policy__text}>
          No está permitido crear browser ni un border enirnment sobre las
          secciones del sitio web, ni de ninguna otra forma podrá modificarse el
          sitio web.
        </p>
        <p className={styles.container_policy__text}>
          No está permitido realizar manifestaciones o indicaciones falsas o
          inexactas o incorrectas sobre el sitio web y/o, en particular,
          declarar o dar a entender que
          <span className={styles.container_policy__name}>
            {' '}
            Connecting Tech People.{' '}
          </span>
          ha autorizado el enlace o que ha supervisado o asumido de cualquier
          forma los contenidos o servicios ofrecidos o puestos a disposición en
          la página web en la que se establece dicho enlace La página web en la
          que se establezca el enlace al sitio web no contendrá informaciones o
          contenidos ilícitos, contrarios a la moral y buenas costumbres
          generalmente aceptadas y al orden público, así como tampoco contendrá
          contenidos contrarios a cualesquiera derechos de terceros, incluidos
          los derechos de propiedad intelectual e industrial y/o el derecho al
          honor, a la intimidad personal o familiar o a la propia imagen o de
          cualquier otro derecho, o contenidos contrarios a las normas
          reguladoras de la protección de datos de carácter personal.
        </p>
        <p className={styles.container_policy__text}>
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no tiene facultad ni medios humanos ni técnicos para conocer,
          controlar ni aprobar toda la información, contenidos, productos o
          servicios facilitados por otras páginas web que tengan establecidos
          enlaces con destino al sitio web.
        </p>
        <p className={styles.container_policy__text}>
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no asume ningún tipo de responsabilidad por cualquier aspecto relativo
          a la página web que establece ese enlace con destino al sitio web, en
          concreto, a título enunciativo y no taxativo, sobre su funcionamiento,
          acceso, datos, información, archivos, calidad y fiabilidad de sus
          productos y servicios, sus propios enlaces y/o cualquiera de sus
          contenidos, en general.
        </p>
        <p className={styles.container_policy__text}>
          Responsabilidades y Garantías En consecuencia,{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People{' '}
          </span>
          no garantiza ni se hace responsable de: La continuidad de los
          contenidos del sitio web La ausencia de errores en dichos contenidos
          La ausencia de virus y/o demás componentes dañinos en el sitio web y/o
          la imposibilidad de vulnerar las medidas de seguridad que se adopten
          en el mismo La falta de utilidad o rendimiento de los contenidos del
          sitio web Y los daños o perjuicios que cause, a sí mismo o a un
          tercero, cualquier persona que infringiera las condiciones, normas e
          instrucciones que{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          establece en el sitio web o a través de la vulneración de los sistemas
          de seguridad del sitio web.
        </p>
        <p className={styles.container_policy__text}>
          Ello no obstante,{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          declara que ha adoptado todas las medidas, dentro de sus posibilidades
          y del estado de la técnica, para garantizar el funcionamiento del
          sitio web y reducir al mínimo los errores del sistema, tanto desde el
          punto de vista técnico como legal y organizativo. Si el usuario
          tuviera conocimiento de la existencia de algún contenido ilícito,
          ilegal, contrario a las leyes o que pudiera suponer una infracción de
          derechos de terceros, deberá notificarlo urgentemente a{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          para que ésta pueda proceder a la adopción de las medidas oportunas.
        </p>
        <p className={styles.container_policy__text}>
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no será responsable de la veracidad, integridad o actualización de las
          informaciones publicadas en el sitio web provenientes de fuentes
          ajenas al mismo y no asumirá responsabilidad en cuanto a hipotéticos
          perjuicios que pudieran originarse por el uso de las citadas
          informaciones.
        </p>
        <p className={styles.container_policy__text}>
          Suspensión del sitio web{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          se reserva el derecho a suspender, modificar, restringir o
          interrumpir, ya sea temporal o permanentemente, el acceso, navegación,
          uso, alojamiento y/o descarga del contenido y/o uso de servicios del
          sitio web, con o sin previa notificación, sin que medie la posibilidad
          del usuario de exigir indemnización alguna por esta causa.
        </p>
        <p className={styles.container_policy__text}>
          Condiciones Generales de uso de perfiles sociales Connecting Tech
          People pretende que todos los usuarios finales conozcan cuáles son sus
          derechos y obligaciones desde el momento en que visitan sus perfiles
          sociales en Facebook, Twitter, y/o en cualquier red social análoga.
        </p>
        <p className={styles.container_policy__text}>
          Del mismo modo,{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          informa a los usuarios sobre sus derechos y obligaciones en relación
          con los contenidos expuestos a través de sus perfiles sociales,
          logotipos y marcas utilizadas, así como las responsabilidades que
          pueden derivarse del uso del servicio.
        </p>
        <p className={styles.container_policy__text}>
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          está profundamente comprometido con que el servicio funcione
          correctamente y conforme a las condiciones acordadas con los usuarios.
        </p>
        <p className={styles.container_policy__text}>
          No obstante, en ocasiones es posible que se produzcan, (especialmente
          por la intervención de terceros mal intencionados), situaciones de las
          que se pudieran derivar responsabilidades.
        </p>
        <p className={styles.container_policy__text}>
          En este sentido, a continuación se indican aquellas situaciones, en
          las que{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no se hace responsable de las actuaciones de los usuarios, asumiendo
          éstos todas las responsabilidades que pudieran derivarse:
        </p>
        <p className={styles.container_policy__text}>
          En caso de que aparezca publicada en la red social cualquier
          información que no hubiera sido publicada o compartida por parte de
          <span className={styles.container_policy__name}>
            {' '}
            Connecting Tech People{' '}
          </span>
          o que en su caso hubiera sido publicada por un tercero ajeno.
        </p>
        <p className={styles.container_policy__text}>
          En caso de que la red social no se encuentre operativa por razones
          técnicas imputables al dueño de la propia plataforma, terceros o
          causas imprevisibles o de fuerza mayor, siendo estas circunstancias
          responsabilidad de la red social, o en su caso del tercero.
        </p>
        <p className={styles.container_policy__text}>
          En caso de que la red social modifique sus condiciones generales de
          uso, políticas de privacidad o modo de funcionamiento, siendo estas
          circunstancias responsabilidad de la red social.
        </p>
        <p className={styles.container_policy__text}>
          En caso de que el usuario almacene, difunda, publique o distribuya en
          la red social cualquier tipo de material difamatorio, injurioso,
          discriminatorio, que incite a la violencia o que sea contrario a la
          moral, el orden público, los derechos fundamentales, las libertades
          públicas, el honor, la intimidad o la imagen de terceros.
        </p>
        <p className={styles.container_policy__text}>
          En caso de que el usuario utilice la red social para introducir datos,
          virus o código malicioso en los equipos de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          o de cualquier otro usuario.
        </p>
        <p className={styles.container_policy__text}>
          En caso de que cualquiera de los contenidos accesibles a través de los
          perfiles sociales de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          fuera contrario a la normativa vigente, éste se compromete a proceder
          a su retirada inmediata, tan pronto tenga conocimiento de esta
          situación.
        </p>
        <p className={styles.container_policy__text}>
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no se compromete a informar a los usuarios cuándo sus comentarios y
          publicaciones sean objeto de edición y/o moderación; sin embargo podrá
          advertir y tomar las medidas oportunas para suspender y expulsar a los
          usuarios que envíen contenido inapropiado periódica y
          sistemáticamente.
        </p>
        <p className={styles.container_policy__text}>
          Aquellos usuarios que lleven a cabo las mencionadas conductas en más
          de una ocasión, habiendo sido suspendidos o expulsados del servicio
          con anterioridad se les podrán impedir el uso del perfil social con
          carácter indefinido, suponiendo esto la expulsión definitiva del
          programa y el bloqueo permanente del usuario.
        </p>
        <p className={styles.container_policy__text}>
          Así mismo,{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no garantiza la veracidad, fiabilidad, vigencia o integridad del
          contenido de terceros que contiene su perfil social o cualquier sitio
          enlazado.
        </p>
        <p className={styles.container_policy__text}>
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no es responsable del contenido de los sitios web no operados por{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People{' '}
          </span>
          El perfil social de
          <span className={styles.container_policy__name}>
            {' '}
            Connecting Tech People{' '}
          </span>
          puede incluir opiniones, recomendaciones o manifestaciones de
          terceros, que no reflejan necesariamente las opiniones de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          ni indican su compromiso con una determinada línea de actuación.
        </p>
        <p className={styles.container_policy__text}>
          La información proporcionada a través del perfil de Connecting Tech
          People en las redes sociales no debe considerarse en ningún caso
          asimilable al asesoramiento profesional apropiado en relación con sus
          circunstancias y consultas concretas, para así lograr la máxima
          eficacia de las medidas adoptadas.
        </p>
        <p className={styles.container_policy__text}>
          En ningún caso la información facilitada a través de medios sociales
          podrá ser considerada una alternativa a la información proporcionada
          personalmente por parte de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          recomienda a los usuarios que actúen con cautela, sentido común y
          sensatez al utilizar su perfil social y que evalúen minuciosamente la
          veracidad, vigencia, integridad y pertinencia de la información
          publicada a través del mismo para sus fines.
        </p>
        <p className={styles.container_policy__text}>
          Toda la decisión que los usuarios adopten basándose en dicha
          información correrá estrictamente por su cuenta y riesgo.
        </p>
        <p className={styles.container_policy__text}>
          El usuario, una vez comience a seguir los perfiles sociales de
          <span className={styles.container_policy__name}>
            {' '}
            Connecting Tech People{' '}
          </span>
          podrá publicar comentarios, enlaces, imágenes o fotografías o
          cualquier otro tipo de contenido, según las condiciones de la propia
          red social.
        </p>
        <p className={styles.container_policy__text}>
          El usuario, en todos los casos, debe ser el titular de los mismos,
          gozar de los derechos de explotación de propiedad intelectual o contar
          con el consentimiento de los terceros afectados.
        </p>
        <p className={styles.container_policy__text}>
          El usuario que decida compartir contenidos en el perfil social de
          <span className={styles.container_policy__name}>
            {' '}
            Connecting Tech People{' '}
          </span>
          debe ser consciente que sus publicaciones serán accesibles y por tanto
          conocidas por los otros usuarios, por lo que él mismo será el único
          responsable de los mismos, así como de las implicaciones que esto
          pudiera tener para su privacidad.
        </p>
        <p className={styles.container_policy__text}>
          Se prohíbe expresamente cualquier publicación en el perfil social de
          <span className={styles.container_policy__name}>
            {' '}
            Connecting Tech People{' '}
          </span>
          que atente o sea susceptible de atentar contra la moral, la ética, el
          buen gusto o el decoro, y/o que infrinja, viole o quebrante los
          derechos de propiedad intelectual o industrial, el derecho a la
          imagen, privacidad o la normativa vigente.
        </p>
        <p className={styles.container_policy__text}>
          En estos casos
          <span className={styles.container_policy__name}>
            {' '}
            Connecting Tech People
          </span>{' '}
          se reserva el derecho a retirar el contenido, pudiendo solicitar el
          bloqueo permanente del usuario que llevó a cabo la publicación de los
          mismos.
        </p>
        <p className={styles.container_policy__text}>
          Se prohíbe expresamente cualquier publicación en el perfil social de
          <span className={styles.container_policy__name}>
            {' '}
            Connecting Tech People{' '}
          </span>
          que atente o sea susceptible de atentar contra la moral, la ética, el
          buen gusto o el decoro, y/o que infrinja, viole o quebrante los
          derechos de propiedad intelectual o industrial, el derecho a la
          imagen, privacidad o la normativa vigente.
        </p>
        <p className={styles.container_policy__text}>
          En estos casos,{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          se reserva el derecho a retirar el contenido, pudiendo solicitar el
          bloqueo permanente del usuario que llevó a cabo la publicación de los
          mismos.
        </p>
        <p className={styles.container_policy__text}>
          En este sentido, los contenidos, opiniones o respuestas a preguntas
          publicados, expresados o enviados por los creadores, patrocinadores,
          anunciantes o usuarios del perfil de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          , distintos del contenido proporcionado por Nombre del responsable del
          tratamiento , no son opiniones y responsabilidad exclusivas de la
          persona que los envía y no reflejan necesariamente las opciones de
          <span className={styles.container_policy__name}>
            {' '}
            Connecting Tech People{' '}
          </span>
          , que no es responsable del contenido que publiquen, cuelguen,
          distribuyan, difundan o transmitan a través de los perfiles oficiales
          de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          .Del mismo modo,
          <span className={styles.container_policy__name}>
            {' '}
            Connecting Tech People
          </span>{' '}
          se reserva el derecho a no responder individualmente a los comentarios
          y mensajes recibidos a través de su perfil, así como a no seguir a
          todos los usuarios que comiencen a seguir su perfil social.
        </p>
        <p className={styles.container_policy__text}>
          Determinada información recogida en los perfiles de Connecting Tech
          People puede contener declaraciones prospectivas, incluidas, a título
          meramente enunciativo más no limitativo, las declaraciones que son
          predicciones o indicativas de futuros acontecimientos, tendencias,
          planes u objetivos.
        </p>
        <p className={styles.container_policy__text}>
          No es recomendable que el usuario se atenga única y exclusivamente a
          estas declaraciones ya que, por su naturaleza, están sujetas a
          incertidumbres y riesgos conocidos y desconocidos.
        </p>
        <p className={styles.container_policy__text}>
          Por todo lo expuesto,{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no asume ninguna obligación de comunicar públicamente las
          actualizaciones o revisiones de estas declaraciones prospectivas, ya
          sea para reflejar nueva información o futuros acontecimientos o
          circunstancias o para otros fines.
        </p>
        <p className={styles.container_policy__text}>
          Para respuestas a preguntas, quejas o reclamaciones, consúltese el
          sitio web corporativo de
          <span className={styles.container_policy__name}>
            {' '}
            Connecting Tech People{' '}
          </span>
          en donde están disponibles los canales de información pertinentes.
        </p>
        <p className={styles.container_policy__text}>
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          utilizará en las redes sociales para publicar sus propios servicios.
        </p>
        <p className={styles.container_policy__text}>
          En todo caso, si{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          decidiera tratar sus datos de contacto (perfil de usuario de la red
          social y/o correo electrónico) para realizar acciones directas de
          prospección comercial, será siempre, cumpliendo con las exigencias
          legales en relación a la protección de datos y servicios de la
          sociedad de la información.
        </p>
        <p className={styles.container_policy__text}>
          No tendrá consideración de publicidad el hecho de recomendar a otros
          usuarios los perfiles sociales de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          para que también ellos puedan estar informados de su actividad.
        </p>
        <p className={styles.container_policy__text}>
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          está profundamente comprometido con la protección y defensa de los
          derechos de propiedad intelectual e industrial.
        </p>
        <p className={styles.container_policy__text}>
          Por ello informa que: En ningún caso podrá entenderse que cede al
          usuario ningún derecho de explotación sobre los contenidos expuestos
          más allá de lo estrictamente necesario para la visualización de los
          mismos.
        </p>
        <p className={styles.container_policy__text}>
          Todos los logotipos, marcas y nombres comerciales expuestos a través
          del sitio web o de la plataforma, son propiedad de Connecting Tech
          People y su utilización se encuentra condicionada por las normas
          corporativas de la compañía, y/o lo dispuesto por aquellos terceros
          que hayan permitido su utilización y/o explotación.
        </p>
        <p className={styles.container_policy__text}>
          Bajo ningún concepto la navegación a través de los perfiles sociales
          de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          puede suponer la renuncia, transmisión, licencia o cesión total o
          parcial respecto a los derechos de propiedad intelectual o industrial,
          salvo que se establezca por escrito lo contrario.
        </p>
        <p className={styles.container_policy__text}>
          En lo que respecta a los links o hiperenlaces, es posible que
          faciliten el acceso a sitios web de terceros independientes que se
          encuentran directamente relacionados con la publicidad mostrada y
          reproducida a través del perfil social.
        </p>
        <p className={styles.container_policy__text}>
          En lo que respecta a los links o hiperenlaces, es posible que
          faciliten el acceso a sitios web de terceros independientes que se
          encuentran directamente relacionados con la publicidad mostrada y
          reproducida a través del perfil social.
        </p>
        <p className={styles.container_policy__text}>
          Al no ser páginas de propiedad de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          y sobre las que tenga control,{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no se hace responsable de los contenidos y/o funcionamiento de las
          mismas.
        </p>
        <p className={styles.container_policy__text}>
          En lo que respecta a los links o hiperenlaces, es posible que
          faciliten el acceso a sitios web de terceros independientes que se
          encuentran directamente relacionados con la publicidad mostrada y
          reproducida a través del perfil social.
        </p>
        <p className={styles.container_policy__text}>
          Al no ser páginas de propiedad de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          y sobre las que tenga control,{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          no se hace responsable de los contenidos y/o funcionamiento de las
          mismas.
        </p>
        <p className={styles.container_policy__text}>
          En caso de que cualquiera detecte algún tipo de contenido contrario a
          la normativa vigente o que puede ser dañino para los usuarios,
          <span className={styles.container_policy__name}>
            {' '}
            Connecting Tech People{' '}
          </span>
          ruega que se le notifique con la mayor brevedad posible.
        </p>
        <p className={styles.container_policy__text}>
          El usuario puede acceder en todo momento a las políticas de privacidad
          de la propia red social, así como configurar su perfil para garantizar
          su privacidad.
        </p>
        <p className={styles.container_policy__text}>
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          anima a los usuarios a familiarizarse con las condiciones generales de
          uso de dichas redes antes de comenzar a usarlo.
          <br />
          Google: http://www.google.es/intl/es/policies/privacy/
          <br />
          Linkedin: https://www.linkedin.com/legal/privacy-policy
          <br />
          <br />
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          podrá modificar los términos y condiciones aquí estipuladas, total o
          parcialmente, publicado cualquier cambio en la misma forma en que
          aparecen estas condiciones de uso o a través de cualquier tipo de
          comunicación dirigida a los usuarios.
        </p>
        <p className={styles.container_policy__text}>
          La vigencia temporal de las presentes condiciones de uso coincide, por
          tanto, con el tiempo de su exposición, hasta que sean modificadas
          total o parcialmente, momento en el cual pasarán a tener vigencia las
          condiciones de uso modificadas.
        </p>
        <p className={styles.container_policy__text}>
          Con independencia de lo dispuesto en las condiciones particulares que
          en su caso se establezcan,{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          podrá dar por terminado, suspender o interrumpir, en cualquier momento
          y sin necesidad de preaviso, el acceso a los contenidos del sitio web,
          sin posibilidad por parte del usuario de exigir indemnización alguna.
        </p>
        <p className={styles.container_policy__text}>
          Tras dicha extinción, seguirán vigentes las prohibiciones de uso de
          los contenidos expuestas anteriormente en las presentes condiciones de
          uso.
        </p>
        <p className={styles.container_policy__text}>
          A estos efectos,{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          informa de que podrá poner en conocimiento y colaborar oportunamente
          con las autoridades policiales y judiciales competentes si detectase
          cualquier infracción de la legislación vigente o si tuviera sospecha
          de la comisión de algún delito.
        </p>
        <p className={styles.container_policy__text}>
          En el caso de que cualquier disposición de las presentes Condiciones
          de Uso fuese declarada nula o inaplicable, en su totalidad o en parte,
          por cualquier Juzgado, Tribunal u órgano administrativo competente,
          dicha nulidad o inaplicación no afectará a las restantes disposiciones
          de las presentes Condiciones de Uso.
        </p>
        <p className={styles.container_policy__text}>
          El no ejercicio o ejecución por parte de{' '}
          <span className={styles.container_policy__name}>
            Connecting Tech People
          </span>{' '}
          de cualquier derecho o disposición contenido en las presentes
          condiciones de uso no constituirá una renuncia al mismo, salvo
          reconocimiento y acuerdo por escrito por su parte.
        </p>
        <p className={styles.container_policy__text}>
          Legislación aplicable y jurisdicción competente siempre que la
          normativa vigente al efecto prevea la posibilidad para las partes de
          someterse a un fuero determinado, para toda cuestión litigiosa
          derivada o relacionada con este sitio web será de aplicación la
          legislación española vigente en el momento del litigio, y nos
          someteremos a los Juzgados y Tribunales de Madrid, así como, en su
          caso, a los Tribunales Arbitrales de consumo o semejantes a los que
          nos encontremos adheridos en el momento de producirse la controversia.
        </p>
      </article>
    </main>
  );
};

export default PrivatePolicy;
