// extracted by mini-css-extract-plugin
export var body_background = "#f8f9fa";
export var container_policy = "privatePolicy-module__container_policy___lLAFf";
export var container_policy__content = "privatePolicy-module__container_policy__content___eQypd";
export var container_policy__content_logo = "privatePolicy-module__container_policy__content_logo___Bn9-5";
export var container_policy__name = "privatePolicy-module__container_policy__name___miv89";
export var container_policy__subtitle = "privatePolicy-module__container_policy__subtitle___tX3+P";
export var container_policy__text = "privatePolicy-module__container_policy__text___ITqnl";
export var container_policy__title = "privatePolicy-module__container_policy__title___IN4xn";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var lg = "1200px";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "#2ed3ae";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var white = "#fff";
export var xl = "1536px";
export var xxl = "2500px";